<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#mports'">{{ $t('imofalform4menu.maintab') }}</v-tab>
				<v-tab v-if="!isCreatingElement" :key="1" :href="'#mportsfacilitiestab'">{{ $t('mportfacilities.maintab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'mports'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<pui-field-set :title="$t('mports.fieldset.portdata')">
								<v-row dense>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											:id="`countrycode-mports`"
											:attach="`countrycode-mports`"
											:label="$t('mports.countrycode')"
											disabled
											toplabel
											clearable
											modelName="mcountries"
											v-model="model"
											reactive
											required
											:itemsToSelect="itemsToSelectCountrycodee"
											:modelFormMapping="{ countrycode: 'countrycode' }"
											:itemValue="['countrycode']"
											:itemText="(item) => ` ${item.countrycode} - ${item.countrydesc} `"
										></pui-select>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-text-field
											:id="`portcode-mports`"
											v-model="model.portcode"
											:label="$t('mports.portcode')"
											disabled
											required
											toplabel
											maxlength="3"
										></pui-text-field>
									</v-col>
									<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-text-field
											:id="`portdescription-mports`"
											v-model="model.portdescription"
											:label="$t('mports.portdescription')"
											disabled
											toplabel
											required
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="'mportsfacilitiestab'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="mportfacilities-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ portcode: 'portcode', countrycode: 'countrycode' }"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mports-form',
	tabmodel: 'mports',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'mports'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		itemsToSelectCountrycodee() {
			return [{ countrycode: this.model.countrycode }];
		}
	},
	created() {}
};
</script>
